
      import { useRequestURL } from 'nuxt/app';

      const dynamicRoutePrefix = '/:site()';

      const rewritePrefixRoute = (route, prefix) => {
        if (route.path.startsWith(prefix)) {
          return {
            ...route,
            path: route.path.replace(prefix, ""),
          };
        }
        return route;
      }

      const ignoreDynamicRoute = (route) => !route.path.startsWith(dynamicRoutePrefix);
      const sites = new Set(["equans","kuma"]);
      const ignoreTenantSitesRoute = (route) => !sites.has(route.name);

      export default {
        routes: (routes) => {
          const { hostname } = useRequestURL();
          const customDomains = {"www.equans.ch":"equans","www.kuma-evt.ch":"kuma"};
          const customDomainRoute = customDomains[hostname];
          if (customDomainRoute) {
            return routes
              .filter(ignoreDynamicRoute)
              .filter(route => route.path.startsWith('/' + customDomainRoute))
              .map((route) => rewritePrefixRoute(route, '/' + customDomainRoute));
          }

          const rootDomain = ["ddev.site","platformsh.site","localhost"].find(domain => hostname.endsWith(domain));

          if (!rootDomain) {
            return routes;
          }
          if (hostname === rootDomain) {
            return routes.filter(ignoreDynamicRoute).filter(ignoreTenantSitesRoute);
          }

          let subdomain;
          if (false) {
            subdomain = hostname.substring(0, hostname.indexOf(rootDomain) - 1);
          } else {
            subdomain = hostname.split('.')[0];
          }

          const tenant = subdomain;

          if (sites.has(tenant)) {
            return routes
              .filter(ignoreDynamicRoute)
              .filter(route => route.path.startsWith('/' + tenant))
              .map((route) => rewritePrefixRoute(route, '/' + tenant));
          }

          return routes
            .map((route) => rewritePrefixRoute(route, dynamicRoutePrefix))
        },
      };
      