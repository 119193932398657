import { defineNuxtPlugin } from '#app';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.directive('inline-html', {
    mounted(el, binding) {
      updateContent(el, binding.value);
    },
    updated(el, binding) {
      updateContent(el, binding.value);
    }
  });
});

function updateContent(el: HTMLElement, html: string) {
  if (!el || !html) return;

  // Use a template element to parse the HTML safely
  const template = document.createElement('template');
  template.innerHTML = html.trim();

  // Clear the current element's content
  while (el.firstChild) {
    el.removeChild(el.firstChild);
  }

  // Append the parsed HTML content
  el.appendChild(template.content.cloneNode(true));
}
