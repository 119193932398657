import { default as index9yanhiMdHIMeta } from "/app/pages/equans/[...slug]/index.vue?macro=true";
import { default as _91uuid_93BBhvl8vjj7Meta } from "/app/pages/equans/blokkli/library-item/[uuid].vue?macro=true";
import { default as indexEoEbTG75zuMeta } from "/app/pages/equans/index.vue?macro=true";
import { default as indexbaqJEHZH7cMeta } from "/app/pages/equans/node/[nid]/index.vue?macro=true";
import { default as index0JPO6hpfeAMeta } from "/app/pages/equans/search/index.vue?macro=true";
import { default as example0mLn9CnviKMeta } from "/app/pages/equans/static-page/example.vue?macro=true";
import { default as indexAkQ7wgBis3Meta } from "/app/pages/kuma/[...slug]/index.vue?macro=true";
import { default as _91uuid_93JN25RFBmxXMeta } from "/app/pages/kuma/blokkli/library-item/[uuid].vue?macro=true";
import { default as indexJeujvmqEi8Meta } from "/app/pages/kuma/index.vue?macro=true";
import { default as indexRDNLUT7V0oMeta } from "/app/pages/kuma/node/[nid]/index.vue?macro=true";
import { default as index9wilRgoCAyMeta } from "/app/pages/kuma/search/index.vue?macro=true";
import { default as examplenJm0V05ytoMeta } from "/app/pages/kuma/static-page/example.vue?macro=true";
export default [
  {
    name: "drupal-route",
    path: "/:slug(.*)*",
    meta: index9yanhiMdHIMeta || {},
    component: () => import("/app/pages/equans/[...slug]/index.vue")
  },
  {
    name: "equans-blokkli-library-item-uuid",
    path: "/equans/blokkli/library-item/:uuid()",
    meta: _91uuid_93BBhvl8vjj7Meta || {},
    component: () => import("/app/pages/equans/blokkli/library-item/[uuid].vue")
  },
  {
    name: "home",
    path: "/equans",
    meta: indexEoEbTG75zuMeta || {},
    component: () => import("/app/pages/equans/index.vue")
  },
  {
    name: "node-canonical",
    path: "/equans/node/:nid()",
    meta: indexbaqJEHZH7cMeta || {},
    component: () => import("/app/pages/equans/node/[nid]/index.vue")
  },
  {
    name: "search",
    path: "/equans/search",
    meta: index0JPO6hpfeAMeta || {},
    component: () => import("/app/pages/equans/search/index.vue")
  },
  {
    name: "static-page-example",
    path: "/equans/static-page/example",
    meta: example0mLn9CnviKMeta || {},
    component: () => import("/app/pages/equans/static-page/example.vue")
  },
  {
    name: "drupal-route",
    path: "/:slug(.*)*",
    meta: indexAkQ7wgBis3Meta || {},
    component: () => import("/app/pages/kuma/[...slug]/index.vue")
  },
  {
    name: "kuma-blokkli-library-item-uuid",
    path: "/kuma/blokkli/library-item/:uuid()",
    meta: _91uuid_93JN25RFBmxXMeta || {},
    component: () => import("/app/pages/kuma/blokkli/library-item/[uuid].vue")
  },
  {
    name: "home",
    path: "/kuma",
    meta: indexJeujvmqEi8Meta || {},
    component: () => import("/app/pages/kuma/index.vue")
  },
  {
    name: "node-canonical",
    path: "/kuma/node/:nid()",
    meta: indexRDNLUT7V0oMeta || {},
    component: () => import("/app/pages/kuma/node/[nid]/index.vue")
  },
  {
    name: "search",
    path: "/kuma/search",
    meta: index9wilRgoCAyMeta || {},
    component: () => import("/app/pages/kuma/search/index.vue")
  },
  {
    name: "static-page-example",
    path: "/kuma/static-page/example",
    meta: examplenJm0V05ytoMeta || {},
    component: () => import("/app/pages/kuma/static-page/example.vue")
  }
]