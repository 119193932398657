export const appRootId = "nuxt-root"

export const appPageTransition = {"name":"page","mode":"out-in"}

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"}],"link":[{"rel":"stylesheet","type":"text/css","media":"screen","href":"/fonts/fontawesome/fontawesome.css"},{"rel":"stylesheet","type":"text/css","media":"screen","href":"/fonts/roboto/roboto.css"},{"rel":"stylesheet","type":"text/css","media":"screen","href":"https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css"},{"rel":"stylesheet","type":"text/css","media":"screen","href":"https://etrex.dev/equans/theme/css/_minified/prerendered.style.css"},{"rel":"stylesheet","type":"text/css","media":"screen","href":"/custom.css"}],"style":[],"script":[{"body":true,"src":"https://etrex.dev/equans/theme/js/plugins/jquery-3.6.3.js"},{"body":true,"src":"https://etrex.dev/equans/theme/js/etx/etx-inviewport-1.2.js"},{"body":true,"src":"https://etrex.dev/equans/theme/js/etx/etx-lazyload-1.0.js"},{"body":true,"src":"https://etrex.dev/equans/theme/js/etx/etx-accordion-1.1.js"},{"body":true,"src":"https://etrex.dev/equans/theme/js/etx/etx-lightbox-1.0.js"},{"body":true,"src":"https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.js"},{"body":true,"src":"https://etrex.dev/equans/theme/js/custom/ui.js"}],"noscript":[],"title":"Equans","htmlAttrs":{"tagDuplicateStrategy":"merge","lang":"de"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootTag = "div"

export const appRootAttrs = {"id":"nuxt-root"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#nuxt-root'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false